import React from "react"
import { Link } from "gatsby"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { ArrowRight } from 'lucide-react'

function ProductCard({ product }) {
  const author = !product.Author ? null : product.Author[0].Author_Id;

  return (
    <Card className="flex flex-col">
      <div className="flex justify-center items-center p-6 bg-background rounded-t-lg">
        <Link
            href={`/${product.SubCategoryId.CategoryId.Slug}/${product.SubCategoryId.Slug}/${product.Slug}`}
            className="inline-flex items-center gap-2 text-primary hover:underline underline-offset-4"
          >
        <img
          src={`https://cms.wesolveit.com.br/assets/${product.Image.filename_disk}?fit=cover&width=291&height=381&quality=80`}
          alt={`Capa do livro ${product.Name}`}
          className="aspect-[3/4] object-cover rounded-t-lg"
          width={300}
          height={400}
        />
        </Link>
      </div>
      <CardHeader>
        <Link
            href={`/${product.SubCategoryId.CategoryId.Slug}/${product.SubCategoryId.Slug}/${product.Slug}`}
            className="inline-flex items-center gap-2 text-primary hover:underline underline-offset-4"
          >
          <CardTitle className="line-clamp-2">{product.Name}</CardTitle>
        </Link>
        {author?.Name && <CardDescription>por {author.Name}</CardDescription>}
      </CardHeader>
      <CardContent className="flex-1">
        <p className="line-clamp-3">
          {product.MetaDescription}
        </p>
      </CardContent>
      <CardFooter>
        <Link
          href={`/${product.SubCategoryId.CategoryId.Slug}/${product.SubCategoryId.Slug}/${product.Slug}`}
          className="inline-flex items-center gap-2 text-primary hover:underline underline-offset-4"
        >
          Assistir resumo completo
          <ArrowRight className="h-4 w-4" />
        </Link>
      </CardFooter>
    </Card>
  );
}

export default ProductCard;