import React from "react"
import { Link } from "gatsby";
import { Button } from "@/components/ui/button"

export default function HeroSection() {
  return (
    <section className="w-full py-12 md:py-24 lg:py-32 theme-color text-white">
      <div className="container mx-auto px-4 md:px-6 max-w-6xl">
        <div className="flex flex-col items-center justify-center text-center">
          <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none max-w-3xl mb-4">
            Desenvolva suas habilidades de liderança
          </h1>
          <p className="max-w-[700px] text-gray-300 md:text-xl mb-8">
            Insights práticos e conhecimento essencial para gestores modernos através de artigos e resenhas de livros
            selecionados.
          </p>
          <Link to="/blog">
            <Button variant="secondary" size="lg">
              Explorar Artigos
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
}