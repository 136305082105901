import React from "react";
import Layout from "@/components/layout";
import SEO from "@/components/seo";
import ReactMarkdownWithHtml from "react-markdown";
import VideoEmbbed from "@/components/video-embbed";
import ArticleHeader from "@/components/blog/article-header"
import SocialButtons from "@/components/blog/social-share-buttons"
import LastBlogPosts from "@/components/blog/last-blog-posts"

function ArticleTemplate({ pageContext: { article, relatedArticles } }) {
    const ldJson = [];
    const breadcrumbs = [];
    breadcrumbs.push({ name: "Blog", link: `/blog` });
    breadcrumbs.push({ name: article.Title, link: `/blog/${article.Slug}` });

    const fullUrl = `https://liderancaegestao.online/blog/${article.Slug}`;

    const breadcrumbsLd = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": breadcrumbs.map((item, index) => {
            return {
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": `https://liderancaegestao.online${item.link}`
            }
        })
    };
    ldJson.push(breadcrumbsLd);

    const imageUrl = !!article?.CoverImage?.filename_disk ? `https://cms.wesolveit.com.br/assets/${article.CoverImage.filename_disk}` : null;
    return (
        <Layout>
            <SEO title={article.Title} 
                 ldJson={ldJson} 
                 description={article.MetaDescription} 
                 keywords={article.MetaKeywords}
                 contentType="article" 
                 imageUrl={imageUrl} 
                />
            <ArticleHeader article={article} />
            <main className="flex-1 bg-white">
                <article className="container mx-auto px-4 md:px-6 py-12">
                    <div className="prose prose-lg dark:prose-invert mx-auto max-w-4xl">
                        <p className="lead text-xl sm:text-2xl font-normal mb-8 text-gray-700">
                            {article.MetaDescription}
                        </p>
                        {/* <Breadcrumbs items={breadcrumbs} /> */}
                        {article.VideoPosition == "TOP" &&
                            <VideoEmbbed videoLink={article.VideoUrl} videoTitle={null} />
                        }

                        <ReactMarkdownWithHtml children={article.Description} />
                        <SocialButtons url={fullUrl} title={article.Title} contentType="product" />
                        {/* <SocialShare url={fullUrl} title={article.Title} contentType="product" /> */}

                        {article.VideoPosition == "BOTTOM" &&
                            <VideoEmbbed videoLink={article.VideoUrl} videoTitle={null} />
                        }
                    </div>
                </article>
                <LastBlogPosts articles={relatedArticles} head="Artigos Relacionados" />
            </main>
        </Layout>
    )
}

export default ArticleTemplate