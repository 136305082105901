import React, { useCallback } from "react"
import { Button } from "@/components/ui/button"
import { Share2, LinkedinIcon, TwitterIcon, FacebookIcon } from 'lucide-react'

const SocialButtons = ({url, title, contentType = "URL"}) => {
  const shareData = {
    title,
    url,
    text: "Liderança e Gestão Online."
  }

  const shareCallback = async (payload) => {
    try {
      await navigator.share(payload);
      console.info("shared successfully");
    } catch (err) {
      console.info(`shared with ERROR: ${err}`);
    }
  }

  const trackShareClick = useCallback((socialName, itemId) => {
    if (!window.gtag)
      return;
  
    window.gtag('event', 'share', {
      "method": socialName,
      "content_type": contentType,
      "item_id": itemId,
    });
  }, []);

  return (
    <div className="flex justify-center gap-4 mt-12 max-w-3xl mx-auto">
      <a id="linkedinShare" className="social-share" onClick={() => trackShareClick("linkedin", url)} href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`} target="_blank" rel="noopener external nofollow" aria-label={`Compartilhe no LinkedIn`}>
      <Button variant="outline" size="lg" className="gap-2" aria-labelledby="linkedinShare">
        <LinkedinIcon className="h-5 w-5" />
      </Button>
      </a>
      <a id="twitterShare" className="social-share" onClick={() => trackShareClick("twitter", url)} href={`https://twitter.com/intent/tweet?text=${title}&url=${url}`} target="_blank" rel="noopener external nofollow" aria-label={`Compartilhe no Twitter`}>
      <Button variant="outline" size="lg" className="gap-2" aria-labelledby="twitterShare">
        <TwitterIcon className="h-5 w-5" />
      </Button>
      </a>
      <a id="facebookShare" className="social-share" onClick={() => trackShareClick("facebook", url)} href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank" rel="noopener external nofollow" aria-label={`Compartilhe no Facebook`}>
      <Button variant="outline" size="lg" className="gap-2" aria-labelledby="facebookShare">
        <FacebookIcon className="h-5 w-5" />
      </Button>
      </a>
      <Button onClick={async () => await shareCallback(shareData)} variant="outline" size="lg" className="gap-2" aria-label={`Compartilhe no seu dispositivo`}>
        <Share2 className="h-5 w-5" />
      </Button>
    </div>
  );
}



export default SocialButtons;