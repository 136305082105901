import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroSection from "../components/hero-section"
import LastBlogPosts from "../components/blog/last-blog-posts"
import LastProducts from "../components/product/last-products"

const IndexPage = ({ data }) => {
  const products = data.Directus.Product.reverse() || [];
  const articles = data.Directus.Article || [];
  return (
    <Layout>
      <Seo title="Principais livros sobre Liderança e Gestão" />
      <HeroSection />
      <LastBlogPosts articles={articles} />
      <LastProducts products={products} />
    </Layout>
  );
}

export const query = graphql`
    query NewestBooks {
        Directus {
            Product(sort: "Id") {
                Author {
                  Author_Id {
                    Name
                    Slug
                  }
                }
                Name
                Slug
                MetaDescription
                Image {
                    filename_disk
                }
                SubCategoryId {
                    Slug
                    Name
                    CategoryId {
                        Name
                        Slug
                    }
                }
            }
            Article(filter: {status: {_eq: "published"}}, sort: "date_created") {
              Id
              Title
              Slug
              MetaDescription
              CoverImage {
                filename_disk
                description
              }
              SubCategory {
                  Slug
                  Name
                  CategoryId {
                      Name
                      Slug
                  }
              }
              date_created
            }
        }
    }
    `;

export default IndexPage
