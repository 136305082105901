import React from "react"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { BookOpen, Share2 } from 'lucide-react'
import { Link } from "gatsby"
import { shareCallback } from "../../lib/utils"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export default function ProductInfo({ product, trackEvent }) {
  const category = product.SubCategoryId.CategoryId;
  const subcategory = product.SubCategoryId;


  const fullUrl = `https://liderancaegestao.online/${category.Slug}/${subcategory.Slug}/${product.Slug}`;
  const shareData = {
    title: product.Name,
    url: fullUrl,
    text: "Liderança e Gestão Online."
  }

  const trackClick = () => {
    if (!trackEvent) {
      return;
    }

    trackEvent();
  }

  return (
    <section className="w-full py-12">
          <div className="container mx-auto px-4 md:px-6">
            <div className="max-w-6xl mx-auto">
              <div className="flex flex-col md:flex-row gap-8">
                <div className="w-full md:w-1/3">
                  <img 
                    src={product.OriginalImageUrl} 
                    alt={product.Name}
                    width={300}
                    height={450}
                    className="w-full rounded-lg shadow-lg"
                  />
                </div>
                <div className="w-full md:w-2/3">
                  <div className="flex flex-wrap gap-2 mb-4">
                    <Badge className="text-sm font-normal">{category.Name}</Badge>
                    <Badge className="text-sm font-normal">{subcategory.Name}</Badge>
                  </div>
                  <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight text-gray-900 mb-4">
                    {product.Name}
                  </h1>
                  <p className="text-xl text-gray-600 mb-6">
                    por {product.Author.map((author, index) => <Link key={author.Author_Id.Name} to={`/autor/${author.Author_Id.Slug}/`}>{index > 0 ? ", " : ""}{author.Author_Id.Name}</Link> )}
                  </p>
                  <div className="prose prose-lg text-gray-700 mb-6">
                    <p>
                      {product.MetaDescription}
                    </p>
                  </div>
                  <div className="flex flex-wrap gap-4">
                    <OutboundLink href={product.Link} target="_blank" rel="noopener">
                      <Button onClick={trackClick} aria-labelledby="linkAmazon" size="lg" className="gap-2 text-base font-semibold">
                        <BookOpen className="h-5 w-5" id="linkAmazon"/>
                        Saiba Mais
                      </Button>
                    </OutboundLink>
                    <Button onClick={async () => await shareCallback(shareData)} aria-labelledby="compartilhar" variant="outline" size="lg" className="gap-2 text-base font-semibold">
                      <Share2 className="h-5 w-5" id="compartilhar" />
                      Compartilhar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  );
}