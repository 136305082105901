import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export default function LegalAdvice({ bookName, bookLink }) {
    return (
        <section id="PdfDownload" className="w-full py-12 pdfDownloadContainer">
            <div className="container mx-auto px-4 md:px-6">
                <div className="max-w-6xl mx-auto prose prose-lg">
                    <h2>Downloads do PDF de <strong>{bookName}</strong></h2>
                    <div class="highlight">
                        <p>
                            Este site <strong>não fornece downloads ilegais de livros em formato PDF</strong> ou qualquer outro formato que viole direitos autorais.
                            O compartilhamento ou download de obras protegidas por direitos autorais sem autorização é uma prática ilegal, sujeita a sanções civis
                            e criminais conforme a <strong>Lei de Direitos Autorais (Lei nº 9.610/1998)</strong> no Brasil.
                        </p>
                    </div>
                    <h3>Por que isso é importante?</h3>
                    <p>
                        Ao comprar o livro <b>{bookName}</b> legalmente e respeitar os direitos autorais:
                    </p>
                    <ul>
                        <li>Você apoia os autores e editoras a continuarem produzindo conteúdos de qualidade.</li>
                        <li>Evita consequências legais, como multas e processos judiciais.</li>
                        <li>Contribui para uma comunidade de leitores mais ética.</li>
                    </ul>
                    <h3>Alternativas Legais</h3>
                    <p>
                        Para acessar o livro "<strong>{bookName}</strong>" de maneira legal, recomendamos as seguintes opções:
                    </p>
                    <ul>
                        <li><strong>Compra:</strong> Verifique os sites oficiais de venda, como <OutboundLink href={bookLink} target="_blank" rel="noopener">Amazon</OutboundLink> ou outras livrarias confiáveis.</li>
                        <li><strong>Bibliotecas Digitais:</strong> Plataformas como o <OutboundLink href="https://amzn.to/40qrcfF" target="_blank" rel="noopener">Kindle Unlimited</OutboundLink> ou a Biblioteca Nacional Digital podem oferecer o título de forma legal.</li>
                        <li><strong>Amostras Gratuitas:</strong> Muitos autores e editoras disponibilizam amostras gratuitas para leitura em plataformas confiáveis.</li>
                        <li><strong>Amostras <OutboundLink href="https://amzn.to/4avzIi8" target="_blank" rel="noopener">Kindle</OutboundLink>:</strong> Mesmo sem assinar o kindle unlimeted, você pode pedir uma amostra da versão digital livro na <OutboundLink href={bookLink} target="_blank" rel="noopener">Amazon</OutboundLink> e ler no seu celular ou dispositivo <OutboundLink href="https://amzn.to/4avzIi8" target="_blank" rel="noopener">Kindle</OutboundLink>.</li>
                    </ul>
                    <h3>Aviso Adicional</h3>
                    <p>
                        Se você encontrou este site em busca de "download PDF de {bookName}," pedimos que considere as opções legais acima.
                        Aqui, você encontrará uma análise detalhada, vídeo de resenha e uma transcrição para ajudar na sua decisão de leitura.
                    </p>
                </div>
            </div>
        </section>
    );
}