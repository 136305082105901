import React from "react"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Clock, ArrowRight } from 'lucide-react'
import { Link } from "gatsby"
import { readingTime } from "@/lib/utils"

export default function BlogCard({ article, key }) {
  const timeToRead = readingTime(article.Description);
  return (
    <Card className="flex flex-col" key={key}>
      <CardHeader>
        <Badge className="w-fit mb-2 text-sm">{article.SubCategory?.Name ?? "Categoria"}</Badge>
        <Link
          href={`/blog/${article.Slug}`}
          className="inline-flex items-center gap-2 text-primary hover:underline underline-offset-4 text-base font-semibold"
        >
          <CardTitle className="text-2xl font-bold mb-2 line-clamp-2">{article.Title}</CardTitle>
        </Link>
        <CardDescription className="flex items-center gap-2 text-base">
          {timeToRead > 0 && <>
            <Clock className="h-4 w-4" />
            <span>{timeToRead} min de leitura</span>
          </>
          }
        </CardDescription>
      </CardHeader>
      <CardContent className="flex-1">
        <p className="text-base line-clamp-3">
          {article.MetaDescription}
        </p>
      </CardContent>
      <CardFooter>
        <Link
          href={`/blog/${article.Slug}`}
          className="inline-flex items-center gap-2 text-primary hover:underline underline-offset-4 text-base font-semibold"
        >
          Ler mais
          <ArrowRight className="h-4 w-4" />
        </Link>
      </CardFooter>
    </Card>
  );
}