import React from "react"

export default function HeroVideoSection({ videoLink, videoTitle, name }) {

  return (
    <section className="w-full bg-gray-900 py-12">
      <div className="container mx-auto px-4 md:px-6">
        <div className="max-w-4xl mx-auto">
          <div className="aspect-video bg-gray-800 rounded-lg overflow-hidden">
            <iframe
              className="w-full h-full"
              src={videoLink}
              title={videoTitle ?? `Review do livro ${name}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}