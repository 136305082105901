import React from "react"
import { Badge } from "@/components/ui/badge"
import { Clock } from 'lucide-react'
import { readingTime } from "@/lib/utils"

const ArticleHeader = ({article}) => {
  const imageUrl = !!article?.CoverImage?.filename_disk ? `https://cms.wesolveit.com.br/assets/${article.CoverImage.filename_disk}` : null;

  const backgroundWithImage = !!imageUrl 
                              ? {
                                backgroundImage: `linear-gradient(to top, rgba(1, 1, 39, 0.6), rgba(1, 1, 39, 0.5)), url('${imageUrl}')`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                width: "100%"
                              }
                              : null;
  return (
    <header className="w-full py-12 md:py-20 lg:py-32 theme-color text-white" style={backgroundWithImage}>
      <div className="container mx-auto px-4 md:px-6">
        <div className="flex flex-col items-center text-center max-w-3xl mx-auto">
          <Badge className="mb-4 text-base" variant="secondary">{article.SubCategory.Name}</Badge>
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight mb-6 font-sans">
            {article.Title}
          </h1>
          <div className="flex items-center gap-4 text-gray-300 text-sm sm:text-base">
            <div className="flex items-center gap-2">
              <Clock className="h-5 w-5" />
              <span className="text-base">{readingTime(article.Description)} min de leitura</span>
            </div>
            <span>•</span>
            <span className="text-base">{formatDate(article.date_created)}</span>
          </div>
        </div>
      </div>
    </header>
  );
}

const formatDate = (value) => {
  let date = new Date(value);
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return day + ' de ' + month + ', ' + year;
}

export default ArticleHeader;