import React from "react"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { BookOpen } from 'lucide-react'
import ProductCard from "./product-card"
import { Link } from "gatsby"

const LastProducts = ({ products, head }) => {

  if (!products || products.length === 0) {
    return (<p>Ops... estamos atualizando essa página.</p>);
  }

  head ??= "Resumos de Livros em Destaque";

  return (
    <section className="w-full py-12 md:py-24 lg:py-32 bg-muted">
      <div className="container mx-auto px-4 md:px-6">
        <div className="flex flex-col items-start gap-4">
          <div className="flex items-center gap-4 mb-10">
            <BookOpen className="h-6 w-6" />
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl" key="0">{head}</h2>
          </div>
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {products.reverse().map(i =>
              <ProductCard product={i} key={i.Id} />
            )}
            <Card className="flex flex-col">
              <div className="flex flex-col items-center justify-center h-full p-6 text-center">
                <h3 className="text-xl font-semibold mb-2">Mais Resenhas</h3>
                <p className="text-muted-foreground mb-4">
                  Explore nossa biblioteca completa de resenhas de livros sobre liderança e gestão.
                </p>
                <Link to="/">
                  <Button variant="outline">Ver Biblioteca</Button>
                </Link>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LastProducts