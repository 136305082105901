import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/breadcrumbs";
import ReactMarkdownWithHtml from "react-markdown"
import LastProducts from "../components/product/last-products"
import LastBlogPosts from "@/components/blog/last-blog-posts"

function CategoryTemplate({ pageContext: { category, subcategories, products, articles } }) {
    const ldJson = [];
    const breadcrumbs = [];
    if (!subcategories) {
        breadcrumbs.push({ name: category.CategoryId.Name, link: `/${category.CategoryId.Slug}` });
        breadcrumbs.push({ name: category.Name, link: `/${category.CategoryId.Slug}/${category.Slug}` });
    }
    else {
        breadcrumbs.push({ name: category.Name, link: `/${category.Slug}` });
    }

    const breadcrumbsLd = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": breadcrumbs.map((item, index) => {
            return {
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": `https://liderancaegestao.online${item.link}`
            }
        })
    }
    ldJson.push(breadcrumbsLd);

    return (
        <Layout>
            <SEO title={category.Name} ldJson={ldJson} description={category.MetaDescription} keywords={category.MetaKeywords} />
            <div className="section-container">
                <Breadcrumbs items={breadcrumbs} />
            </div>
            <section className="section">
                <div className="section-container">
                    <h1>{category.Name}</h1>
                    <div className="flex flex-col md:flex-row pb-14">
                        <div className="w-full md:w-12/12 mt-4 md:mt-0 md:ml-1">
                            <p className="leading-normal pt-2">
                                <ReactMarkdownWithHtml children={category.Description} />
                            </p>
                        </div>
                    </div>
                </div>
                {!!articles && articles.length > 0 && <LastBlogPosts articles={articles} head={`Artigos sobre ${category.Name}`} />}
                <LastProducts products={products} head={`Livros sobre ${category.Name}`} />
            </section>

        </Layout>
    )
}

export default CategoryTemplate