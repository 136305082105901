import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ReactMarkdownWithHtml from "react-markdown"

const AuthorPage = ({ data }) => {
  const authors = data.Directus.Author || [];

  if (authors.length === 0) {
    return (<p>Ops... estamos atualizando essa página.</p>);
  }

  return (
    <Layout>
      <section className="section">
        <div className="section-container">
          <Seo title="Principais autores de livros sobre Liderança e Gestão" description="Principais autores de livros sobre Liderança e Gestão" />
          <h1>Principais autores de livros sobre Liderança e Gestão</h1>
          {authors.map(i => {
            return (
              <div>
                <h2><Link to={`/autor/${i.Slug}`} title={`Veja todos os livros de ${i.Name}`}>{i.Name}</Link></h2>
                <hr />
                <ReactMarkdownWithHtml children={i.Description} />
              </div>
            );
          })}
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
    query Authors {
      Directus {
        Author(sort: "Name") {
          Id
          Name
          Slug
          Description
        }
      }
    }
    `

export default AuthorPage