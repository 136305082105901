import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <section className="section">
      <div className="section-container">
        <h1>Essa página parece estar sem liderança no momento</h1>
        <p>Não se preocupe, você está no controle! Use os links abaixo para voltar ao caminho certo.</p>
        <ul>
          <li className="mb-1">
            <Link className="text-black" to="/blog">Blog</Link>
          </li>
          <li className="mb-1">
            <Link className="text-black" to="/blog/influencia-da-cultura-organizacional-na-gestao-de-equipes/">A Influência da Cultura Organizacional na Gestão de Equipes</Link>
          </li>
          <li className="mb-1">
            <Link className="text-black" to="/lideranca-e-gestao/transicao-para-a-lideranca/">Transição para a Liderança</Link>
          </li>
          <li className="mb-1">
            <Link className="text-black" to="/lideranca-e-gestao/tecnicas-eficazes-de-gestao/">Técnicas Eficazes de Gestão</Link>
          </li>
          <li className="mb-1">
            <Link className="text-black" to="/lideranca-e-gestao/livros-de-ceos/a-regra-e-nao-ter-regras-a-netflix-e-a-cultura-da-reinvencao">A Regra é Não Ter Regras</Link>
          </li>
          <li className="mb-1">
            <Link className="text-black" to="/lideranca-e-gestao/desenvolvimento-pessoal-para-lideres/">Desenvolvimento Pessoal para Líderes</Link>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage
