import React from "react"
import BlogCard from "./blog-card"
import { TrendingUp } from 'lucide-react'

const LastBlogPosts = ({ articles, head }) => {

  if (!articles || articles.length === 0) {
    return (<p>Ops... estamos atualizando essa página.</p>);
  }

  head ??= "Artigos em Destaque";
  return (
    <section className="section">
      <div className="section-container">
        <div className="flex flex-col items-start gap-4 mb-10">
          <div className="flex items-center gap-2">
            <TrendingUp className="h-6 w-6 text-primary" />
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">{head}</h2>
          </div>
        </div>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {articles.reverse().map(i =>
            <BlogCard article={i} key={i.slug} />
          )}
        </div>
      </div>
    </section>
  );
}

export default LastBlogPosts