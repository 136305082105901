import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LastBlogPosts from "../components/blog/last-blog-posts"

const BlogPage = ({ data }) => {
  const articles = data.Directus.Article || [];

  if (articles.length === 0) {
    return (<p>Ops... estamos atualizando essa página.</p>);
  }

  return (
    <Layout>
      <Seo title="Blog" description="Artigos sobre liderança e gestão" />
      <LastBlogPosts articles={articles} head="Artigos sobre liderança e gestão" />
      <div className="pb-16"></div>
      <div className="pb-16"></div>
    </Layout>
  );
}

export const query = graphql`
query Articles {
  Directus {
    Article(filter: {status: {_eq: "published"}}, sort: "date_created") {
      Id
      Title
      Slug
      MetaDescription
      CoverImage {
        filename_disk
        description
      }
      date_created,
      SubCategory {
        Name
        Slug
        Id
				CategoryId {
					Name
          Slug
          Id
        }
      }
    }
  }
}
    `

export default BlogPage